import React, { Component } from 'react';
import Web3 from "web3"
import { Web3EthereumProvider } from 'web3-providers';
import { CardManager } from './CardManager';
import { MetamaskSubprovider, ZeroExProvider } from '@0x/subproviders';
import { Web3Wrapper } from '@0x/web3-wrapper';



interface MetamaskWindow {
    web3?: {
        currentProvider: Web3EthereumProvider
    },
    ethereum?: ZeroExProvider
}


async function getProvider(window: MetamaskWindow): Promise<Web3Wrapper | undefined> {
    if ((typeof window.web3 !== undefined) &&
        (window.ethereum !== undefined) &&
        (window.ethereum.enable !== undefined)) {
        await window.ethereum.enable()
        return new Web3Wrapper(window.ethereum);
    }
}

interface MetamaskComponentArgs {
    enabled: boolean
    provider: Web3Wrapper | undefined
    address: string
}


export class MetamaskComponent extends Component<{}, MetamaskComponentArgs> {

    static signCardId(cardId: number): string {
        return "Signed"
    }

    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            enabled: false,
            address: '',
            provider: undefined
        };
    }

    async componentDidMount() {
        let address = ""
        let enabled = false
        const provider = await getProvider(window as MetamaskWindow)
        if (provider) {
            const accounts = await provider.getAvailableAddressesAsync()
            if (accounts.length > 0) {
                address = accounts[0].toLowerCase()
                enabled = true;
            }
        }
        this.setState({
            provider,
            enabled,
            address,
        });
    }

    render() {
        const state = this.state;
        return (state.enabled && state.provider)  ? (
            <CardManager client={state.provider} loggedInAddress={state.address} />
        ): (
            <div className="col-md-12 no-metamask pt-5 rounded">
                <div className="row">
                    <div className="col-md-2 offset-md-2">
                        <img src="/images/metamask.svg" alt=""/>
                    </div>
                    <div className="col-md-5 offset-md-1">
                        <h3>Oh no! it looks like you don't have MetaMask</h3>
                        <a href="https://metamask.io/" target="_blank" className="btn-style my-5 btn btn-lg mt-0">Install it here</a>
                    </div>
                </div>
            </div>
        )
    }
}