import React, { Component } from 'react';
import Web3 from 'web3';
import { ERC721TokenWrapper, ERC721ProxyWrapper, ERC721TokenTransferEventArgs, ERC721TokenEvents} from '@0x/contract-wrappers';
import { Web3Wrapper } from '@0x/web3-wrapper';
import { getContractAddressesForNetworkOrThrow } from '@0x/contract-addresses';
import { CYCLE_ABI, NETWORK_TO_CONFIG_MAP } from './constants';
import { MetamaskSubprovider } from '@0x/subproviders'
import { Web3EthereumProvider } from 'web3-providers';
import { Card } from './Card';


interface CardManagerProps {
    client: Web3Wrapper
    loggedInAddress: string
}

export interface CardMeta {
    tokenId: number
    owner: string
    networkId: number
}

interface CardManagerState {
    tokenWrapper?: ERC721TokenWrapper
    cards: CardMeta[]
    networkId: number
}

export class CardManager extends Component<CardManagerProps, CardManagerState> {
    constructor(props: CardManagerProps) {
        super(props);
        this.state = {
            cards: [],
            networkId: 1
        }
    }

    componentWillUnmount() {
        if (this.state.tokenWrapper) {
            this.state.tokenWrapper.unsubscribeAll();
        }
    }

    async renderCards() {
        const web3ProviderInstance = this.props.client.getProvider()
        const client = new Web3(web3ProviderInstance as Web3EthereumProvider)

        const {CYCLE_CONTRACT_ADDRESS} = NETWORK_TO_CONFIG_MAP[this.state.networkId]
        const cycleContract = new client.eth.Contract(CYCLE_ABI, CYCLE_CONTRACT_ADDRESS)
        const totalSupply: number = await cycleContract.methods.totalSupply().call()

        const cards: CardMeta[] = []
        for (let index = 0; index < totalSupply; index++) {
            let ownerId: string = await cycleContract.methods.ownerOf(index).call();
            cards.push({
                tokenId: index,
                owner: ownerId.toLowerCase(),
                networkId: this.state.networkId
            })
        }
        this.setState({
            cards: cards
        })
    }

    async componentDidMount() {
        const networkId = await this.props.client.getNetworkIdAsync()
        const netAddresses = getContractAddressesForNetworkOrThrow(networkId)
        const erc721ProxyWrapper = new ERC721ProxyWrapper(this.props.client, networkId, netAddresses.erc721Proxy)
        const tokenWrapper = new ERC721TokenWrapper(this.props.client, networkId, erc721ProxyWrapper)

        const {CYCLE_CONTRACT_ADDRESS} = NETWORK_TO_CONFIG_MAP[networkId]

        tokenWrapper.subscribe(
            CYCLE_CONTRACT_ADDRESS,
            ERC721TokenEvents.Transfer, 
            {
                maker: "0x618b1673A843732BC726674e2079BD4020EF63a9".toLowerCase()
            },
            (err, log) => {
                this.renderCards()
            },
            true,
        )

        this.setState({
            tokenWrapper,
            cards: [],
            networkId
        })

        await this.renderCards()
    }

    render() {
        const cardsMap = this.state.cards.map(card => {
            return (
                <div className="col-md-6" key={card.tokenId}>
                    <Card loggedInAddress={this.props.loggedInAddress} key={card.tokenId} meta={card} wrapper={this.props.client} />
                </div>
            )
        })

        return (
            <div className="col-md">
                <div className="row">
                    {cardsMap}
                </div>
            </div>
        );
    }
}