import React, { Component } from 'react';
import map from './map.png';
import cyclist from './cyclist.png'
import './App.css';
import { MetamaskComponent } from './MetamaskComponent';

class App extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md map">
            <img src={map} className="img-fluid"/>
          </div>
          <div className="col-md mt-5">
            <div className="my-1" id="header-title">
              In June 2019 I am going on a <span className="orange-text">7 day cycle journey </span>
               to raise money and <span className="orange-text">end AIDS</span>
            </div>
            <div className="my-1 header-desc">
              This is the first time I do any fundraising or have I cycled so far across 7 days, but I believe that with your help I can make this happen. 
            </div>
            <div>
              <a href="#cards-wrapper" className="btn-style my-5 btn btn-lg">Support my journey</a>
            </div>
          </div>
        </div>
        <div className="row" id="cards-wrapper">
          <div className="col-md">
            <h1 className="text-center">Adopt one day of my journey</h1>
            <div className="row">
            <div className="col-md-8 offset-md-2 text-center mb-3">
              Want to share my journey with me? Adopt one (or more) days by purchasing ALC token (a special token I created for my fund-raising). I will share the videos and photos of that day with you. All the money collected
              will go towards AIDS/LifeCycle to help their mission of ending AIDS in our lifetime.
            </div>
            </div>
          </div>
        </div>
        <div className="row">
          <MetamaskComponent />
        </div>
        <div className="row mt-5">
          <div className="col-md">
            <img className="img-fluid" src={cyclist} alt="Me!"/>
          </div>
          <div className="col-md footer-title">
            <h3>Hello, I am Daniel</h3>
            <p>I'm an engineer at <a href="http://0x.org">0x.org</a> and this year I’ve decided to participate in the AIDS LifeCycle with the goal to end AIDS and challenge myself.</p>
            <p>Since I've embarked myself in this mission to know more about AIDS, I've learned that there are some cures that can make HIV undetectable and people can have a healthy, positive life. Such life-saving and life-enabling
               medication can only come with years of research and investment.</p>

            <p>Today I am proposing CycleToken. A fun project that I built to raise the remaining $700 that I need for my campaign! CycleToken is a unique collection of 7 tokens: one for each day of AIDS Lifecycle. Each token is worth $100. All money will
               be directly donated to charity (I will “mirrror” the donation on my <a href="http://www.tofighthiv.org/site/TR/Events/AIDSLifeCycleCenter?px=3443595&pg=personal&fr_id=2110">public fundraising page</a>, and I will add the token ID as a reference). </p>
            <p>If you purchase a "day" (in the form of a token), you will also have exclusive access to the media content that I create on that day. A option to "Explore Photos" will appear as soon as a token is purchased.</p>

            <p>Thank you for your support! I'm looking forward to sharing my journey with you.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
