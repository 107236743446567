import React, { Component } from 'react';
import { CardMeta } from './CardManager';
import { Web3Wrapper, SupportedProvider } from '@0x/web3-wrapper';
import { SignedOrder, AssetProxyId, ObjectMap } from '@0x/order-utils';
import { BigNumber } from '@0x/utils';
import { AssetBuyer, BasicOrderProvider } from '@0x/asset-buyer';
import { CYCLE_META, NETWORK_TO_CONFIG_MAP, SERVER_HOST, getHost } from './constants';
import { setInterval } from 'timers';
import { MetamaskComponent } from './MetamaskComponent';
import Web3 from 'web3';
import { Web3EthereumProvider } from 'web3-providers';
import { defaultCipherList } from 'constants';
import { NetworkId } from '@0x/contract-addresses';


interface CardProps {
    meta: CardMeta
    wrapper: Web3Wrapper
    loggedInAddress: string
}

export interface ERC721AssetMetaData {
    assetProxyId: AssetProxyId.ERC721;
    name: string;
    imageUrl?: string;
    primaryColor?: string;
}

export interface ZeroExInstantRequiredBaseConfig {
    orderSource: string | SignedOrder[]
    networkId: number
    provider: SupportedProvider
    availableAssetDatas: string[]
    defaultSelectedAssetData: string
    additionalAssetMetaDataMap: ObjectMap<ERC721AssetMetaData>
}

interface ZeroExInstantWindow {
    zeroExInstant : {
        ERC721_PROXY_ID: string
        render(params: ZeroExInstantRequiredBaseConfig, frame: string): void;
    }
}

enum CardOwnership {
    AvailableForPurchase = "AvailableForPurchase",
    AlreadyPurchased = "AlreadyPurchased",
    PendingPurchase = "PendingPurchase",
    OwnedByMe = 'OwnedByMe',
    Unknown = 'Unknown'
}

interface CardState {
    ownership: CardOwnership
}

interface BaseCardStateWhenAvailable extends CardState {
    ownership: CardOwnership.AvailableForPurchase
    signedOrder?: SignedOrder
}

type CardStates = BaseCardStateWhenAvailable | CardState


interface AlreadyOwnedJSON {
    address_taken?: string
}


interface OrderJSON {
    senderAddress: string;
    makerAddress: string;
    takerAddress: string;
    makerFee: number;
    takerFee: number;
    makerAssetAmount: number;
    takerAssetAmount: number;
    makerAssetData: string;
    takerAssetData: string;
    salt: number;
    exchangeAddress: string;
    feeRecipientAddress: string;
    expirationTimeSeconds: number;
    signature: string
}


export class Card extends React.Component<CardProps, CardStates> {
    private interval?: NodeJS.Timer

    constructor(props: CardProps) {
        super(props);

        this.state = {
            ownership: CardOwnership.Unknown
        }
    }

    async getPurchasedPhotos() {

        // Sign card
        const web3ProviderInstance = this.props.wrapper.getProvider()
        const client = new Web3(web3ProviderInstance as Web3EthereumProvider)

        const message = `I am the owner of the token ${this.props.meta.tokenId}`

        try {
            const signature = await client.currentProvider.send('personal_sign', [message, this.props.loggedInAddress])
            const host = getHost()
            const rawResponse = await fetch(`${host}/ownership/${this.props.meta.tokenId}/verify`, {
                method: 'POST',
                body: JSON.stringify({
                    'signed_message': signature
                })
              });
            if (rawResponse.status != 200) {
                alert("Oops.. something happened! please try again soon!")
            }
            const responseJSON: {[key: string]: string} = await rawResponse.json()
            const photosUrl = responseJSON["photos"];
            const newWindow = window.open(photosUrl, "_blank")
            if (newWindow) {
                newWindow.focus()
            }
        } catch (e) {
            return
        }
    }

    async componentDidMount() {
        this.interval = setInterval(async () => {
            if (this.state.ownership == CardOwnership.AvailableForPurchase) {
                try {
                    let newState = await this.getSignedOrder()
                    this.setState(newState)
                } catch (e) {
                    this.setState({
                        ownership: CardOwnership.AlreadyPurchased,
                    })
                }
            }
        }, 5000)
        return this.reloadState(this.props)
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    componentWillReceiveProps(newProps: CardProps) {
        return this.reloadState(newProps)
    }

    async reloadState(props: CardProps) {
        const {CYCLE_OWNER} = NETWORK_TO_CONFIG_MAP[this.props.meta.networkId]
        switch(props.meta.owner) {
            case props.loggedInAddress: {
                this.setState({
                    ownership: CardOwnership.OwnedByMe,
                })
                break;
            }
            case CYCLE_OWNER: {
                let newState = await this.getSignedOrder()
                this.setState(newState)
                break;
            }
            default: {
                this.setState({
                    ownership: CardOwnership.AlreadyPurchased,
                })
            }

        }
    }
    
    async getSignedOrder(): Promise<BaseCardStateWhenAvailable> {
        const result = await fetch(`${SERVER_HOST}/order/${this.props.meta.tokenId}`)
        if (result.status == 200) {
            let orderJson = (await result.json()) as OrderJSON
            const {expirationTimeSeconds, salt, takerAssetAmount, makerAssetAmount, takerFee, makerFee, ...rest} = orderJson
            const newState: BaseCardStateWhenAvailable = {
                ownership: CardOwnership.AvailableForPurchase,
                signedOrder: {
                    ...rest,
                    expirationTimeSeconds: new BigNumber(expirationTimeSeconds),
                    salt: new BigNumber(salt),
                    takerAssetAmount: new BigNumber(takerAssetAmount),
                    makerAssetAmount: new BigNumber(makerAssetAmount),
                    takerFee: new BigNumber(takerFee),
                    makerFee: new BigNumber(makerFee),
                }
            }
            return newState
        }
        throw new Error("Result failed")
    }

    async purchaseToken() {
        if (this.state.ownership != CardOwnership.AvailableForPurchase) {
            return;
        }
        const stateAvailable = this.state as BaseCardStateWhenAvailable

        if (stateAvailable.signedOrder) {

            const provider = this.props.wrapper.getProvider()
            const networkId = await this.props.wrapper.getNetworkIdAsync()
            const typedWindow = window as unknown as ZeroExInstantWindow

            const visualTokenID = this.props.meta.tokenId + 1
            const currentHref = getHost()
            typedWindow.zeroExInstant.render(
                {
                    provider: provider,
                    orderSource: [stateAvailable.signedOrder],
                    networkId: networkId,
                    availableAssetDatas: [stateAvailable.signedOrder.makerAssetData],
                    defaultSelectedAssetData: stateAvailable.signedOrder.makerAssetData,
                    additionalAssetMetaDataMap: {
                        [stateAvailable.signedOrder.makerAssetData]: {
                            assetProxyId: AssetProxyId.ERC721,
                            name: `Cycle token #${visualTokenID}`,
                            primaryColor: '#FC4C02',
                            imageUrl: `${currentHref}/images/token${visualTokenID}.png`
                        }
                    }
                },
                'body',
            );
        }
    }

    render() {
        let buyButton = null;
        const metadata = CYCLE_META[this.props.meta.tokenId]
        console.log(this.props.meta.tokenId)
        if (this.state.ownership == CardOwnership.AvailableForPurchase) {
            buyButton = (
                <button className="btn-style my-3 btn btn-lg" onClick={this.purchaseToken.bind(this)}>Adopt this day</button>
            )
        } else if (this.state.ownership == CardOwnership.OwnedByMe) {
                buyButton = <button onClick={this.getPurchasedPhotos.bind(this)} className="btn-style my-3 btn btn-lg btn-explore">Explore photos..</button>
        } else {
            let bodyText = (() => {switch(this.state.ownership) {
                case CardOwnership.PendingPurchase:
                    return "Pending purchase.."
                case CardOwnership.AlreadyPurchased:
                    return "Token already purchased"
                default:
                    return "Loading.."
            }})()
            buyButton = (
                <button className="btn-style my-3 btn btn-lg" disabled>
                    {this.state.ownership == CardOwnership.PendingPurchase ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>: ""}
                    {bodyText}
                </button>
            )
        }
        return (
            <div className="row card mx-1 my-4 cycle-card shadow">
                <div className="row py-2 px-4 justify-content-between cycle-card-header pt-3">
                    <div className="col-4">
                        <p>{metadata.day}</p>
                        <h3>Day {this.props.meta.tokenId+1}</h3>
                    </div>
                    <div className="col-8 text-right">{metadata.route}</div>
                </div>
                <div className="row cycle-card-body">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6 px-4">
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td className="card-body-key">Distance</td>
                                            <td className="card-body-value">{metadata.distanceMiles}</td>
                                        </tr>
                                        <tr>
                                            <td className="card-body-key">Elevation</td>
                                            <td className="card-body-value">{metadata.elevation}</td>
                                        </tr>
                                        <tr>
                                            <td className="card-body-key">Difficulty</td>
                                            <td className="card-body-value">{metadata.difficulty}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row cycle-card-button">
                    <div className="col-12 px-4">
                        {buyButton}
                    </div>
                </div>

            </div>
        )
    }
}